@import "../../../style/colors";

.user-teams-list-container {
    height: 90%;
    overflow: hidden;
    padding: 40px 20px 32px 40px!important;

    .MuiTableContainer-root {
        width: 99%;
    }

    .MuiTableCell-root {
        font-size: 13px !important;

        &.MuiTableCell-head {
            background: $grey4;
            color: $grey6;
            border-bottom: 1px solid $grey5;
        }

        &:not(.MuiTableCell-head) {
            border: none;
        }
    }

    .MuiTableBody-root {
        background: $grey4;

        :nth-child(2n) {
            &.MuiTableRow-root {
                background: $grey3;
            }
        }
    }

    .owner {
        max-width: 200px;
        background: $grey2;
        box-shadow: inset 0px -1px 1px rgba(255, 255, 255, 0.25),
            inset 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 16px;

        .avatar-container {
            width: 24px;
            height: 24px;
        }
    }

    .icon {
        height: 24px;
        width: 24px;
        background: $grey6;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;

        &:hover {
            background: $primary-color;
        }
    }
}