.match-sheet {
    position: relative;

    .upper-actions {
        position: absolute;
        right: 1.5%;
    }

    .blink{
        animation: Blink 1s infinite;
    }
    @keyframes Blink{
        0%{opacity: 1;}
        50%{opacity: 0;}
        100%{opacity: 1;}
    }

    .match-actions {
        .MuiAccordion-rounded {
            border-left: solid 2px #b2f617;
            border-bottom: none;
        }
        .MuiPaper-elevation1 {
            box-shadow: none;
        }

        .MuiPaper-root {
            color: #fff;
            background-color: unset !important;
        }

        .MuiAccordion-rounded:last-child {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .MuiAccordion-rounded:first-child {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
    }

    .right-box-area {
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .MuiTabs-root {
        min-height: auto;
        .MuiButtonBase-root{
            text-transform: inherit;
            user-select: text;
            &.Mui-selected{
                border: none;
            }
            &:focus{
                outline: none;
            }
        }
        .MuiTabs-indicator{
            display: none;
        }
    }
    .MuiTabPanel-root{
        padding-top: 0;
    }
}

.MuiDialog-container {
    .result-error {
        color: red;
    }
    
    .result-valid {
        color: green;
    }

    img{
        max-width: 100%
    }
}