@import 'src/style/colors';


.administrator-form {

    .NCADMIN {
        background-color: $administrator !important;
    }
    
    .TOURNAMENT {
        background-color: $tournament !important;
    }
    
    .TRAINING {
        background-color: $training !important;
    }
    
    .PARTNER {
        background-color: $tracking !important;
    }

    .GUEST {
        background-color: $secondary-color-light !important;
    }

    .LEGAL {
        background-color: $primary-dim-color !important;
    }

    .styled-role {
        width: 30%;
        text-align: center;
        padding: 7px 0;
        border-radius: 10px;
        color: $secondary-color-mid;
        font-weight: bold;
    }
}
