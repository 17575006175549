@import 'src/style/colors';

.team-card {
    filter: drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.5));
    border-radius: 8px;
    width: 280px;

    &::after {
        position: absolute;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(8px);
    }

    .content {
        z-index: 1;

        .select {
            background: $grey4;
            border: 1px solid $grey5;
        }
    }

    .close-button {
        width: 24px;
        height: 24px;
        background: $red;
        border-radius: 50%;
        top: 7px;
        right: 7px;
        z-index: 1;

        &:hover {
            background: $grey4;
        }

        .mask-icon {
            width: 8px;
            height: 8px;
            background: $grey1;
        }
    }

    .code {
        color: $grey5;
    }
}

.permission-container {
    .mask-icon {
        height: 24px;
        width: 24px;
        background: $grey6;
    }
}