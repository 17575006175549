
@import 'src/style/colors';

.team-invitation-modal {
    .MuiDialog-paper {
        background-color: rgba(26, 26, 26, 0.6) !important;
        color: $grey6 !important;
        filter: drop-shadow(0 1px 0 rgba(255,255,255,.5));
        backdrop-filter: blur(15px);

        max-width: 900px !important;

        .background-scratched {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            mix-blend-mode: screen;
            opacity: .2;
            background: url('../../../assets/textures/scratch-texture.png');
            border-radius: 8px;
        }

        .content-container {
            z-index: 1;

            .invited {
                background: $grey4;
                color: $grey6;
                border-radius: 16px;
                border: 1px solid $grey5;
                width: fit-content;

                &:hover {
                    border-color: $primary-color;
                }

                .mask-icon {
                    background: $white;
                    width: 12px;
                    height: 12px;

                    &:hover {
                        background: $primary-color;
                    }
                }
            }

            .description {
                color: $grey5;
                font-size: 11px;
            }

            .search-container {
                height: 32px;

                .NC-textfield {
                    width: 80%;
                }

                .search-button {
                    width: 20%;
                    height: 100%;
                    position: unset;

                }
            }

            .search-list {
                max-height: 40vh;
                width: 80%;

                .search-item {
                    background: $white;
                    color: $grey2;
                    padding: 6px 8px;
                    height: 32px;
                    cursor: pointer;

                    &:hover {
                        background: $grey6;
                    }
                }
            }
        }

        .close-icon {
            width: 16px;
            height: 16px;
            background: $white;
            right: 32px;
            top: 32px;
        }
    }
}