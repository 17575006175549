@import "src/style/colors";

.MuiButtonBase-root.MuiAccordionSummary-root {
    user-select: text;
}

.platform-icon {
    display: block;
    width: 24px;
    height: 24px;
    margin: auto;
    &.ps4,
    &.ps5 {
        background: url("../../../assets/platform-icon/ps.svg") no-repeat center / contain;
    }
    &.xbox,
    &.xbox-series {
        background: url("../../../assets/platform-icon/xbox.svg") no-repeat center / contain;
    }
    &.pc {
        background: url("../../../assets/platform-icon/pc.svg") no-repeat center / contain;
    }
    &.switch {
        background: url("../../../assets/platform-icon/switch.svg") no-repeat center / contain;
    }
    &.mobile {
        background: url("../../../assets/platform-icon/mobile.svg") no-repeat center / contain;
    }
    &.pc-mobile {
        background: url("../../../assets/platform-icon/pc-mobile.svg") no-repeat center / contain;
    }
}

.bracket-icon {
    width: 24px;
    height: 24px;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    background: $primary-color;
}