@import "../../../style/colors.scss";

.user-wallet {
    .wallet-select {
        font-size: larger;
    }

    .button-add-funds {
        background: $green;

        &:hover {
            background: lighten($green, 10%);
        }
    }

    .button-remove-funds {
        background: $red;

        &:hover {
            background: lighten($red, 10%);
        }
    }
}
