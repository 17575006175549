@import "../../../style/colors";

.user-teams-list-container {
    .invitations-list-title {
        margin-top: 10px;
        margin-left: -10px;

        span {
            align-items: center;
            display: flex;
        }
    }
}