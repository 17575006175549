@import 'src/style/colors';

.media-container {
    border-radius: 16px;
    border: 4px dashed $white;

    .mask-icon {
        width: 64px;
        height: 64px;
        background: $white;
    }

    .media-input {
        z-index: 1;
        opacity: 0;
        cursor: pointer;
    }
}