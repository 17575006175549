@import "src/style/colors";

.search-bar-new {
    .search-button {
        position: absolute;
        right: 8px;
        top: 8px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        background: $grey6;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;

        &:hover {
            background: $primary-color;
        }
    }
}