@import "src/index";

a {
  text-decoration: none;
  color: $white !important;
}

.MuiListItem-gutters {
  padding-left: 10px !important;
}

.logout-button {
  color: $red;
  :hover {
    cursor: pointer;
  }
}

.profile-menu {
  color: $primary-color;
}


ul {
  .item-icon {
    filter: brightness(0) invert(1);
  }

  .tournament {
    .item-icon {
      filter: none;
      color: $tournament !important;
    }
    :hover {
      .item-icon {
        filter: none;
        color: $tournament !important;
      }
    }
    :hover.MuiListItem-button {
      background-color: $tournament;
    }
  }

  .training {
    .item-icon {
      filter: none;
      color: $training !important;
    }
    :hover {
      .item-icon {
        filter: none;
        color: $training !important;
      }
      .MuiListItemIcon-root {
        color: $white !important;
      }
    }
    :hover.MuiListItem-button {
      background-color: $training;
    }
    .MuiListItemIcon-root {
      color: $white !important;
    }
  }

  .administrator {
    .icon-comments {
        background: $white;
    }

    .item-icon {
      filter: none;
      color: $administrator !important;
    }
    :hover {
      .item-icon {
        filter: none;
        color: $administrator !important;
      }
    }
    :hover.MuiListItem-button {
      background-color: $administrator;
    }
  }

  :hover {
    .item-icon {
      filter: none;
      color: $white !important;
    }
  }
  :hover.MuiListItem-button {
    background-color: $blue-cornflower;
  }
}

.header-background {
  background-color: #0c0c0c !important;
  padding: 0 !important;

  .MuiFormControl-marginNormal {
    margin-top: 0;
  }
}

.active {
  color: $white;
  .MuiListItem-button {
    background-color: $blue-cornflower;
  }
  .MuiListItemIcon-root {
    color: $white !important;
  }
}

.tournament.active {
    color: $white;
    .MuiListItem-button {
      background-color: $tournament;
    }
}

.training.active {
  color: $white;
  .MuiListItem-button {
    background-color: $training;
  }
  .MuiListItemIcon-root {
    color: $white !important;
  }
}

.administrator.active {
  color: $white;
  .MuiListItem-button {
    background-color: $administrator;
  }
}