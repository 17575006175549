@import 'src/style/colors';

.training-module-card {
  height: 224px;
  width: 350px;
  display: inline-table;

  .header {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 12px 16px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  .footer {
    padding: 12px 16px;

    .MuiButton-textPrimary {
      color: $training !important;
    }
  }

  .title {
      min-height: 66px;
      color: $white;
      font-weight: bold;
      font-size: 22px;
  }

  .icon {
      background-color: $training;
      width: 12px;
      height: 12px;
      margin-bottom: 3px;
  }

  .info {
      .prize {
          span {
              font-size: 18px;
          }
      }

      .level {
          mask: url("../../assets/system/level.svg") no-repeat center / contain;
      }

      .calendar {
          mask: url("../../assets/system/calendar.svg") no-repeat center / contain;
      }
  }

  .statistics {
      mask: url("../../assets/system/statistics.svg") no-repeat center / contain;
      background-color: $training-gradient;
  }

  a {
      color: inherit;
  }
}

