.training-dashboard {
    .game-select {
        width: 200px !important;

        .MuiInputLabel-formControl  {
            left: 60px
        }
    }
}

    
.game-select-image {
    width: 50px;
}