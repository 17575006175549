@import 'src/style/colors.scss';

.transaction-detail {
    .hidden {
        display: none !important;
    }

    .action-section {
        max-width: 250px;
    }

    .media-miniature {
        width: 29%;
        max-width: 250px;
        margin: auto 2%;
        padding: 10px 0;
    }

    .transition-status {
        font-weight: bold;

        &.status-validating {
            color: $yellow-school;
        }

        &.status-transiting {
            color: $green;
        }

        &.status-delivered {
            color: $blue;
        }

        &.status-cancelled {
            color: $red;
        }

        &.status-unprocessable {
            color: $orange;
        }
    }

    .validation-status {
        font-weight: bold;

        &.pending {
            color: $yellow-school;
        }

        &.validated {
            color: $green;
        }
    }
}
