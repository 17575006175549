.dashboard-tournament-list {
    height: 500px;
}

.dashboard-matches-list {
    height: 500px;
}

.short-box {
    height: 100px;
}