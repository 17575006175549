@import "src/style/colors";

.tab-panel-container {
    background: $secondary-color-mid;
    input {
        color: $white;
    }
    label {
        color: $primary-color !important;
    }
}