@import 'src/style/colors';

.partner-form {
    .partner-container {

        .partner-image {
            img {
                max-height: 205px;
                border-radius: 8px;
            }
        }
    }
}