@import "src/style/colors.scss";

.training-tools {
  background: #414141;
  text-align: center;
  width: 105px;
  position: fixed;
  bottom: 18%;
  right: 0;
  justify-content: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px #2F2F2F solid;

  .training-tools-button {
    .icon-size {
      color: $training;
      font-size: 45px;
    }

    p {
      font-size: 18px;
    }
  }
}

.background {
  background-color: $grey3 !important;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.module-structure {
  .base {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey3;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 12px $white;
    }
  }

  .block {
    //   border: solid 1px black;
      margin-bottom: 20px;
  }

  .brick {
    width: 100px;
    height: 60px;
    border: solid 1px $grey5;
    border-radius: 10px;
    margin: 0 10px;
  }

  .plus {
    width: 40px;
    height: 20px;
    border: solid 1px #1C1D1E;
    border-radius: 20px;
  }

  .column {
    margin: 20px;
  }
}

.module-information {
    .MuiPaper-root {
        background-color: transparent !important;
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
    }
}