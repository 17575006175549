@import "src/style/colors";

.bg-image {
    background: $secondary-color-dark;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-text {
    color: white;
    background-color: $secondary-color-dim;
    border-radius: 5px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 30%;
    padding: 20px;
    text-align: center;
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 15px 0 rgba(0,0,0,0.75);
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.75);
}

.login-form {
    width: 100%;

}

@media screen and (max-width: 600px) {
    .bg-text {
        width: 80%;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $primary-color;
    transition: background-color 5000s ease-in-out 0s;
    padding: 10px;
    background: $secondary-color-dim;
}