.Cancel-tournament-modal {
    color: white;
    // margin-left: 60px;

    .textAreaWrapper .nicecactus-input textarea {
        white-space: initial;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #c7c3c3;
    }
}

.ncRapper {
    .nc-dialog {
        .dialog-content {
            width: 560px;
            height: 529px;

            &::before {
                background: linear-gradient(
                    360deg,
                    rgba(44, 44, 44, 0.95) 0%,
                    rgba(0, 0, 0, 0.9) 100%
                );
            }
        }
    }
}
