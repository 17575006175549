@import "src/style/colors";

.simple-list {
  height: 90%;
  overflow: hidden;

  .MuiTableContainer-root {
    background-color: transparent !important;
  }

  .MuiSvgIcon-colorSecondary {
    color: $red;
  }
  
  table {

    th {
      color: $white !important;
      font-weight: bold !important;
    }

    td {
      color: $white !important;
    }

    .media {
      height: 20px;
    }

    .MuiTableRow-root:hover {
      background: rgba(0, 0, 0, 0.2);
    }

    .MuiTableRow-root.MuiTableRow-head:hover {
      background: none;
    }
  }
}