@import 'src/style/colors.scss';

.NCTitle {
    .title {
        width: 4px;
        height: 16px;
        margin-right: 8px;
        background: $blue-cornflower;
        color: #000;
    }

    .tournament.title{
        background: $tournament !important;
    }

    .training.title {
        background: $training !important;
    }

    .administrator.title {
        background: $administrator !important;
    }

    span {
        font-size: 22px;
    }
    .title-link {
        color: #888c88;
    }
}
.clickable.NCTitle {
    :hover {
        color: $primary-color
    }
}