@import 'src/style/colors';


.team-details {
    .description {
        color: $grey6;
        font-size: 11px;
    }

    .error-owner {
        color: $red;
        opacity: 0;

        &.active {
            opacity: 1;
        }

        .warning {
            width: 18px;
            height: 18px;
        }
    }

    .add-user {
        background: $grey6;
        width: 24px;
        height: 24px;

        &:hover {
            background: $primary-color;
        }
    }


    h6 {
        font-size: 22px;
    }

    .media {
        height: 160px;

        &.logo {
            width: 160px;
        }
    }

    .team {
        margin-left: 15px;
        margin-right: 15px;
    }

    .MuiInput-root{
        height: 100%;
        text-align: inherit;
    }
}