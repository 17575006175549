@import "src/style/colors.scss";

.match-messaging {
    min-height: 500px;
    .message-box {
        height: 450px;
        overflow-y: scroll;
        .message {
            &.pending {
                opacity: 0.65;
            }

            .message-author {
                font-size: 12px;
                padding-left: 4px;
                font-weight: bold;
            }

            .message-author.me {
                color: #ec7a74;
            }

            .message-content {
                height: 100%;
                border-radius: 3px;
                padding: 7px 5px 5px;

                div {
                    text-overflow: ellipsis;
                    word-break: break-word;
                    height: 100%;
                }
            }

            .message-content.me {
                background: $tournament;
            }

            .message-content.other {
                background: $training;
                color: black;
            }

            .message-date {
                font-size: 12px;
                color: $light-grey;
            }
        }
    }
}