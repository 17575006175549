@import '../../style/colors.scss';

.text-admin {
    color: $red;
}

.transition-status {
    font-weight: bold;

    &.status-validating {
        color: $yellow-school;
    }

    &.status-transiting {
        color: $green;
    }

    &.status-delivered {
        color: $blue;
    }

    &.status-cancelled {
        color: $red;
    }

    &.status-unprocessable {
        color: $orange;
    }
}