.NC-card {
    background-color: $secondary-color-mid;
    box-shadow: 0 15px 25px rgba(0,0,0,.45);
    border-radius: 24px;
    padding: 24px 16px 32px;
    color: #fff;
    margin-bottom: 32px;
  }
  
  .NC-item-card {
    border-radius: 24px;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 5px 8px 0 rgba(0,0,0,.14), 0 1px 14px 0 rgba(0,0,0,.12);
    background-color: #292d30;
    font-size: 14px;
    margin: 0 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    color: #fff;
  }
  
  .NC-item-card:hover {
    box-shadow: 0 10px 13px -6px rgba(0,0,0,.2), 0 20px 31px 3px rgba(0,0,0,.14), 0 8px 38px 7px rgba(0,0,0,.12);
  }