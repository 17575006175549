.loading,
.failure {
    display: none !important;
}

.user-media-image,
.user-media-link {
    width: 100%;
    height: 100%;
}
