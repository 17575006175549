.program-list {
  .select-image-game {
    background: transparent;
    justify-content: flex-end;
    img {
      height: 100%;
      width: 100px;
    }
  }
  .list-container {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

