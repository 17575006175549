@import "src/style/colors";

.categorized-button.MuiButton-root {
    background: $primary-color;
    color: $black;
    font-weight: bold;
    text-transform: uppercase;
    border: 0;
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    color: $black;

    :hover {
        color: $black;
        box-shadow: 0 10px 13px -6px rgba(0,0,0,.2), 0 20px 31px 3px rgba(0,0,0,.14), 0 8px 38px 7px rgba(0,0,0,.12);
    }
}

.training.categorized-button {
     background: $training-gradient !important;
}

.tournament.categorized-button {
    background: $tournament-gradient !important;
}