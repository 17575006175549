.MuiGridListTile-root {
    width: 50%;
    height: 184px; 
    padding: 2px;
}

.MuiGridListTile-tile > .MuiCircularProgress-root {
    margin-left: 45%;
    margin-top: 7%;
}
