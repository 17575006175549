@import "src/style/colors.scss";

.training-tools {
    background: #414141;
    text-align: center;
    width: 105px;
    position: fixed;
    bottom: 18%;
    right: 0;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px #2F2F2F solid;

    .training-tools-button {
        .icon-size {
            color: $training;
            font-size: 45px;
        }

        p {
            font-size: 18px;
        }
    }
}

.tree {
    background-color: $grey4 !important;

    .active {
        box-shadow: 0px 0px 12px $white;
    }


    .base {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grey3;

        &:hover {
            cursor: pointer;

            &.premium {
                box-shadow: 0px 0px 12px $premium-secondary-color;
            }

            &.primary {
                box-shadow: 0px 0px 12px $primary-color;
            }

            &:not(.premium):not(.primary) {
                box-shadow: 0px 0px 12px $white;
            }
        }

        &.circle {
            border-radius: 50%;

            &:not(.premium) {
                filter: grayscale(1);
            }
        }
    }

    .block {
        margin-bottom: 20px;
    }

    .brick {
        width: 120px;
        height: 80px;
        border: solid 1px $grey5;
        border-radius: 10px;
        margin: 0 10px;

        &.premium {
            border-color: $premium-secondary-color;
        }

        &.primary {
            border-color: $primary-color;
        }

        &.new {
            margin-right: 90px !important;
        }

        img {
            width: 25px;
            filter: brightness(0) invert(1);
        }
    }

    .plus {
        width: 40px;
        height: 25px;
        font-size: 20px;
        font-weight: bold;
        border: solid 1px #1C1D1E;
        border-radius: 10px;
    }

    .column {
        margin: 20px;
    }

    .minus {
        color: $red;
    }

    .line {
        color: $primary-color;
    }
}