.skillGame-form {

    .remove-button {
        position: absolute;
        right: 2.5%;
        bottom: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    label {
        margin-bottom: 0 !important;
    }
}

