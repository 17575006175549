@import "colors";
@import "cards";
@import "material";
@import "@wolfiesports/srm-component/dist/styles/mixins";

@font-face {
  font-family: "mollenregular";
  src: url("../assets/fonts/Eko-Bimantara-Mollen-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "mollenregular";
  src: url("../assets/fonts/eko_bimantara_-_mollen-regular-webfont.woff2")
          format("woff2"),
      url("../assets/fonts/eko_bimantara_-_mollen-regular-webfont.woff")
          format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "mollenregular";
  src: url("../assets/fonts/eko_bimantara_-_mollen-bold-webfont.woff2")
          format("woff2"),
      url("../assets/fonts/eko_bimantara_-_mollen-bold-webfont.woff")
          format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "bebas_kairegular";
  src: url("../assets/fonts/bebaskai-webfont.woff2")
          format("woff2"),
      url("../assets/fonts/bebaskai-webfont.woff")
          format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MOLarabicRegular";
  src: url("../assets/fonts/MOLarabicRegular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "serpentinebolditalic";
  src: url("../assets/fonts/serpentine_bolditalic-webfont.woff2")
          format("woff2"),
      url("../assets/fonts/serpentine_bolditalic-webfont.woff")
          format("woff");
  font-weight: normal;
  font-style: normal;
}

.NC-container {
    color: white;
    width: calc(100% - 73px);
    margin-left: 60px;
    min-height: calc(100vh - 64px);
    margin-top: 64px;

  @media screen and (max-width: 600px) {
    .NC-container {
      width: calc(100% - 57px);
      margin-left: 50px;
      padding: 10px 5px;
      min-height: calc(100vh - 56px);
      margin-top: 56px;
    }
  }
}

.NC-partner-container {
  color: white;
  width: 100%;
  min-height: calc(100vh - 64px);
  margin-top: 64px;

  @media screen and (max-width: 600px) {
    .NC-container {
      width: 100%;
      padding: 10px 5px;
      min-height: calc(100vh - 56px);
      margin-top: 56px;
    }
  }
}

a {
  color: $white;
  text-decoration: none !important;
  cursor: pointer;
}

.NC-info {
  background-color: #292d30;
  border-radius: 8px;
  box-shadow: inset 0 -1px 1px hsla(0,0%,100%,.25), inset 0 2px 8px rgba(0,0,0,.25);
  padding: 10px;
  width: 100%;

  .big-text {
      font-size: 22px;
  }

  .title {
      color: #888c88;
  }
}

@mixin scrollbar-custom {
  overflow-y: auto;
  scrollbar-color: $primary-color #0f161d;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 12px;
  height: 3px;

  @include media-breakpoint-down(sm) {
    width: 3px;
  }
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: $secondary-color-dark;
  border-radius: 3px;
  border: 1px none $secondary-color-mid;
}

.mouse-pointer {
    cursor: pointer;
}

::-webkit-scrollbar {
  width: 3px;
}

.line-throught {
  text-decoration: line-through;
}

.NC-textfield {
    background: $black;
    color: $grey6 !important;

    input {
        padding-left: 8px !important;

        &::-webkit-input-placeholder {
            opacity: 1 !important;
        }
    }
}

.avatar-container {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: linear-gradient(217.53deg, #9BFF38 7.47%, #CBFF96 43.54%, #87FF0E 82.54%);

    .avatar {
        position: absolute;
        border-radius: 50%;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        left: 1px;
        top: 1px;
    }
}

.mask-icon {
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
}

.background-fullsize {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}