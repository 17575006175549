@import "src/style/colors";

.MuiAppBar-colorPrimary {
  color: $primary-color !important;
  background-color: $secondary-color-dark !important;
}

.MuiDialog-paperFullScreen {
  height: 85% !important;
  bottom: -8% !important;
}