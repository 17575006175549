@import "colors";

.MuiTableCell-root {
    font-family: 'mollenregular' !important;
}

.MuiFormControl-root {
    font-family: 'mollenregular' !important;
}

.MuiInputBase-root {
    font-family: 'mollenregular' !important;
}

.MuiButtonBase-root {
    font-family: 'mollenregular' !important;
}

.MuiButton-textPrimary {
    color: $primary-color;
    background: none;

    .MuiButton-text {
      background: none;
      box-shadow: none;
    }
}

.MuiButton-textSecondary {
    color: $red !important;
    background: none;

    .MuiButton-text {
        background: none;
        box-shadow: none;
    }


}

.MuiButton-textSecondary:hover {
    background-color: rgba(255, 62, 52, 0.08) !important;
}

.NC-tab {
    .MuiTab-root {
        background-color: #0c0c0c;
        border-radius: 8px 8px 0 0;
        height: 42px;
        color: #fff;
        width: fit-content;
        padding: 9px 24px;
        cursor: pointer;
        margin-right: 8px;
        font-weight: bold;
    }

    .MuiTabs-flexContainer {
        background: #424242 !important;
    }

    .Mui-selected {
        color: $primary-color;
    }

    .MuiPaper-elevation4 {
        box-shadow: none !important;
    }

    .MuiAppBar-colorDefault {
        background: none !important;
    }
}
