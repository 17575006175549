@import 'src/style/colors';

.file-manager {
    .MuiDialog-paper {
        min-height: 700px;
        max-width: 1500px !important;
    }

    .path {
        width: 300px;
    }

    .list-media {
        overflow-y: auto;
        height: 535px;
    }

    .preview {
        padding: 24px;

        img {
            max-height: 400px;
        }
    }

    .select-button {
        bottom: 25px;
        right: 50px
    }
}
