@import "../../../style/colors";

.user-teams-list-container {
    .teams-list-title {
        margin-top: 50px;
        margin-left: -10px;

        span {
            align-items: center;
            display: flex;
        }
    }

    .permission-container {
        gap: 5px;

        .permission-text {
            display: flex;
            align-items: flex-end;
        }
    }
}