@import "src/style/colors";

.search-bar-container {
    background-color: $secondary-color-dim;
    border-radius: 24px;

    .search-button {
        color: $primary-color;
        cursor: pointer;
    }
}