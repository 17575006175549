@import 'src/style/colors';


.administrator-form {
    .NCADMIN {
        background-color: $administrator !important;
    }
    
    .TOURNAMENT {
        background-color: $tournament !important;
    }
    
    .TRAINING {
        background-color: $training !important;
    }
    
    .PARTNER {
        background-color: $tracking !important;
    }
}

.sketch-picker {
    input {
        color: black;
    }
    label {
        color: black !important;
    }
}

.primary-color {
    min-height: 40px;

    .error {
        color: $red;
    }
}

