@import "src/style/colors.scss";

.broadcast {
    @mixin state($color) {
        background-color: $color;
    }

    .state {
        border-radius: 100%;
        width: 15px;
        height: 15px;
    }

    .GREEN {
        @include state($primary-color);
    }

    .YELLOW {
        @include state($yellow);
    }

    .RED {
        @include state($red);
    }
}