@import 'src/style/colors';

.page-container {
    background-size: cover;
    background-repeat: no-repeat;
    width: calc(100% + 3rem);
    height: calc(100vh - 64px);
    margin-top: 16px;
    margin-bottom: -3rem;
    scroll-behavior: smooth;
    padding-left: 64px;
    padding-right: 64px;

    .MuiTableCell-root {
        font-size: 13px !important;

        &.MuiTableCell-head {
            background: $grey4;
            color: $grey6;
            border-bottom: 1px solid $grey5;
        }

        &:not(.MuiTableCell-head) {
            border: none;
        }
    }

    .MuiTableBody-root {
        background: $grey4;

        :nth-child(2n) {
            &.MuiTableRow-root {
                background: $grey3;
            }
        }

        .game-container {
            overflow: hidden;
            position: relative;
            height: 24px;
            width: 168px;
            margin-left: -0.5rem;
            margin-right: -0.5rem;

            .marquee-container {
                position: relative;
                height: 30px;

                .marquee-slider {
                    position: absolute;

                    .icon {
                        min-width: 24px;

                        &:hover {
                            background: $grey6;
                        }
                    }
                }
            }

            .plus {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &:hover {
                .slidable {
                    animation: marquee 1s linear infinite;
                }

                .plus {
                    display: none;
                }

            }
        }
    }

    .owner {
        max-width: 270px;
        background: $grey2;
        box-shadow: inset 0px -1px 1px rgba(255, 255, 255, 0.25), inset 0px 2px 8px rgba(0, 0, 0, 0.25);
        border-radius: 16px;

        .avatar-container {
            width: 24px;
            height: 24px;
        }
    }

    .icon {
        height: 24px;
        width: 24px;
        background: $grey6;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;

        &:hover {
            background: $primary-color;
        }
    }

    .scroll-button {
        position: fixed;
        bottom: 30px;
        right: 30px;
    }

    h5 {
        font-size: 28px;
    }

    .mask-icon {
        background-color: $grey6;

        &:hover {
            background-color: $primary-color;
        }
    }
}

@keyframes marquee {
    0% { left: 0; }
    100% { left: -100%; }
}
