.blink{
    animation: Blink 1s infinite;
}
@keyframes Blink{
    0%{opacity: 1;}
    50%{opacity: 0;}
    100%{opacity: 1;}
}
.MuiTableCell-root{
    font-size: 12px;
}