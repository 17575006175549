@import '@wolfiesports/srm-component/dist/styles/colors';

.nc-promotions {
    .mask-icon {
        background: $grey6;

        &:hover {
            background: $primary-color;
        }
    }
}