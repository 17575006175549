@import "index";

body {
  overflow: hidden;
}

#root {
  background: $secondary-color-dark;
  color: $white;
  font-family: 'mollenregular';
  height: 100vh;
  overflow: auto;
}